$(function() {
    $('.kk-slider-default .slider-wrapper').each(function () {

        $(this).find('.line-mode .textblock').each(function () {
            $(this).find('h5').each(function () {
                $(this).addClass('h2');
                $(this).html('<span class="wrapper"><span class="bg-primary fw-bold text-bg text-white">' + $(this).html() + '</span></span>');
            });
            $(this).find('h4').each(function () {
                $(this).addClass('h1');
                $(this).html('<span class="wrapper"><span class="bg-primary fw-bold text-bg text-white">' + $(this).html() + '</span></span>');
            });
        });


        if($(this).find('.slider-element').length > 1)
        {
            var pauseValue = typeof $(this).data('bx-pause') !== "undefined" && parseInt($(this).data('bx-pause')) > 0 ? parseInt($(this).data('bx-pause')) : 5000;
            var speedValue = typeof $(this).data('bx-speed') !== "undefined" && parseInt($(this).data('bx-speed')) > 0 ? parseInt($(this).data('bx-speed')) : 500;
            var modeValue = typeof $(this).data('bx-mode') !== "undefined" && $(this).data('bx-mode').length > 0 ? $(this).data('bx-mode') : 'horizontal';



            var slider = $(this).bxSlider({
                controls: false,
                auto: true,
                adaptiveHeight: true,
                pause: pauseValue,
                speed: speedValue,
                mode: modeValue,
                preloadImages: 'all',
                touchEnabled: false,
                onSliderResize: function (currentIndex) {
                    $slideElement = $(this).find('.slider-element').eq(currentIndex + 1);
                    $pager = $slideElement.closest('.bx-wrapper').find('.bx-pager');
                    var tH = $slideElement.outerHeight(true) - 17 - $pager.outerHeight(true);
                    if($slideElement.find('figcaption').length > 0)
                    {
                        tH -= $slideElement.find('figcaption').outerHeight(true) - 20;
                    }
                    $pager.css('bottom', 'auto').css('top', tH + 'px');
                },
                onSliderLoad: function (currentIndex) {
                    $slideElement = $(this).find('.slider-element').eq(currentIndex + 1);
                    $pager = $slideElement.closest('.bx-wrapper').find('.bx-pager');
                    var tH = $slideElement.outerHeight(true) - 17 - $pager.outerHeight(true);
                    if($slideElement.find('figcaption').length > 0)
                    {
                        tH -= $slideElement.find('figcaption').outerHeight(true) - 20;
                    }
                    $pager.css('bottom', 'auto').css('top', tH + 'px');

                    // Bugfix Firefox:
                    setTimeout(function (){
                        $slideElement.closest('.slider-wrapper').data('slider-obj').redrawSlider();
                    }, 1);
                },
                onSlideBefore: function ($slideElement, oldIndex, newIndex) {
                    $pager = $slideElement.closest('.bx-wrapper').find('.bx-pager');
                    var tH = $slideElement.outerHeight(true) - 17 - $pager.outerHeight(true);
                    if($slideElement.find('figcaption').length > 0)
                    {
                        tH -= $slideElement.find('figcaption').outerHeight(true) - 20;
                    }
                    $pager.css('bottom', 'auto').css('top', tH + 'px');
                }
            });
            $(this).data('slider-obj', slider);
        }
    });
});
