// Import all plugins
import 'jquery-sticky';
import * as bootstrap from 'bootstrap';
import "./import-jquery";
import 'jquery-focuspoint';
import {getBreakPointName, isWithinBreakpoint} from "./checkBootstrapBreakpoint";
import 'bxslider/dist/jquery.bxslider';
import Cookies from "js-cookie";
import deparam from "jquery-deparam";
import Plyr from "plyr";
import _ from "underscore";

import "./jq-mobile-menu";
import "./jq-sliders";

export default (window._ = _, window.loadJsCssFile = loadJsCssFile);


$(function() {

    // Make small screen header sticky:
    $('.header-sm').sticky({
        topSpacing:1,
        zIndex: 910
    });

    // Make odd/even styled separator lines work globally:
    var sepBorderCt = 0;
    $('hr.sep-border').each(function () {
        if(sepBorderCt++%2 == 1)
        {
            $(this).addClass('odd');
        }
    });

    // Hide some logo and layout elements if hero image is present:
    if($('.hero-element:visible').length > 0)
    {
        $('.no-hero').addClass('d-none');
    }

    // Enable home news teaser
    $('.box-teaser-and-news img').on('load', setHomeNewsTeaserHeight);
    $(window).on('resize.homeNewsBoxHeight', setHomeNewsTeaserHeight);
    setHomeNewsTeaserHeight();


    // Set focuspoint on opening accordions:
    $('.accordion').on('shown.bs.collapse', function (){
        $(this).find('.focuspoint').focusPoint();
    })

    // Set image heights:
    $(window).on('resize.ds81setImageHeights', dsSetImageHeights);
    dsSetImageHeights();

    $('.videocontainer').each(function(){
        $(this).find('.videobox').each(function () {
            ds81_init_videobox($(this));
        });
    });

    // Handle changes in consent selection:
    $(window).on('dscookiebar_consentchange', dscookiebar_handle_consent_change);
    dscookiebar_handle_consent_change();


    // Check and handle cookie bar show/hide:
    if($('#dscookiebar_consent').length > 0)
    {
        // Consent version id:
        var cookieVersion = $('#dscookiebar_consent').first().data('version');

        // Cookie path:
        var cookiePath = typeof path_root !== "undefined" ? path_root : '';

        // Read cookie:
        if(typeof Cookies.get('dscookieconsent_version_' + lang_id, {path: cookiePath}) === "undefined" || Cookies.get('dscookieconsent_version_' + lang_id, {path: cookiePath}) != cookieVersion)
        {
            $('#dscookiebar_consent').each(function () {

                // Prevent form submit:
                $(this).find('form').submit(function(e){
                    e.preventDefault();
                });

                // Link select checkboxes:
                $(this).find('input[type="checkbox"]').each(function (){
                    if(typeof $(this).attr('checked') !== "undefined"){
                        $(this).prop('checked', true);
                    }
                });
                $(this).find('input[type="checkbox"]').on('change', function () {
                    // Transfer status to possibly present cookie bar:
                    $('#dscookiebar_onpage_consent input[type="checkbox"][name="' + $(this).attr('name') + '"]').prop('checked', $(this).prop('checked'));
                });

                $(this).removeClass('d-none');
                $(this).find('.content-select-consent').addClass('d-none');
                $(this).find('.content-default').removeClass('d-none');

                $(this).find('.btn-accept-all').on('click.dscookiebaracceptall', dscookiebar_accept_all_handler);
                $(this).find('.btn-make-choice').on('click.dscookiebarmakechoice', dscookiebar_show_select_consent_content);
                $(this).find('.btn-cancel-consent-select').on('click.dscookiebarcancel', dscookiebar_show_default_content);
                $(this).find('.btn-save-consent-settings').on('click.dscookiebarsaveconsent', dscookiebar_save_consent);

                $('footer').css('margin-bottom', $(this).outerHeight(true) + 'px');

            });

        }

    }

    // Link onpage component:
    if($('#dscookiebar_onpage_consent').length > 0)
    {
        $('#dscookiebar_onpage_consent').each(function () {

            // Prevent form submit:
            $(this).submit(function(e){
                e.preventDefault();
            });

            // Link select checkboxes:
            $(this).find('input[type="checkbox"]').each(function (){
                if(typeof $(this).attr('checked') !== "undefined"){
                    $(this).prop('checked', true);
                }
            });

            // Process checkboxes:
            $(this).find('input[type="checkbox"]').each(function () {

                // Set initial state:
                if(dscookiebar_has_consent($(this).attr('name')))
                {
                    $(this).prop('checked', true);
                }


                // Link checkbox to transfer state to cookie bar:
                $(this).on('change', function () {

                    // Transfer status to possibly present cookie bar:
                    $('#dscookiebar_consent input[type="checkbox"][name="' + $(this).attr('name') + '"]').prop('checked', $(this).prop('checked'));

                    // Check status:
                    dscookiebar_onpage_check_status();

                });

            });

            // Link save button:
            $(this).find('.btn-save-consent-settings').on('click.dscookiebarsaveconsent', dscookiebar_save_consent);

        });

        // Listen to change events to update onpage status:
        $(window).on('dscookiebar_consentchange', function () {

            // Check status:
            dscookiebar_onpage_check_status();

        });

        // Initial status check:
        dscookiebar_onpage_check_status();

    }

    $('.page-slider-default .slider-wrapper').each(function(){

        var pauseValue = typeof $(this).data('bx-pause') !== "undefined" && parseInt($(this).data('bx-pause')) > 0 ? parseInt($(this).data('bx-pause')) : 5000;
        var speedValue = typeof $(this).data('bx-speed') !== "undefined" && parseInt($(this).data('bx-speed')) > 0 ? parseInt($(this).data('bx-speed')) : 500;
        var modeValue = typeof $(this).data('bx-mode') !== "undefined" && $(this).data('bx-mode').length > 0 ? $(this).data('bx-mode') : 'horizontal';

        if($(this).find('.slider-element figcaption').length > 0)
        {
            $(this).find('.slider-element').each(function (){
                if($(this).find('figcaption').length < 1)
                {
                    $(this).find('figure').append('<figcaption style="opacity: 0;"><p>&nbsp;</p></figcaption>');
                }
            });
        }

        $(this).bxSlider({
            controls: false,
            pager: true,
            pagerType: 'default',
            pagerShortSeparator: '/',
            auto: true,
            adaptiveHeight: true,
            pause: pauseValue,
            speed: speedValue,
            mode: modeValue,
            preloadImages: 'all',
            onSliderLoad: function (currentIndex){
                var $elem = $(this).find('.slider-element:visible').eq(currentIndex - 1);
                var capHeight = $elem.find('.img-wrapper').outerHeight(true) + 10;
                $elem.closest('.bx-wrapper').find('.bx-pager').css('top', capHeight + 'px');
            },
            onSlideBefore: function ($elem, oldIndex, newIndex)
            {
                setTimeout(function (){
                    var capHeight = $elem.find('.img-wrapper').outerHeight(true) + 10;
                    $elem.closest('.bx-wrapper').find('.bx-pager').css('top', capHeight + 'px');

                }, 10);

            }
        });
    });

    // Initialize square gallery:
    if($('.square-gallery').length > 0)
    {
        $(window).on('resize.ds81_square_gallery_sizer', ds81_square_gallery_sizer);
        ds81_square_gallery_sizer();
    }


});

function setHomeNewsTeaserHeight()
{
    // News teaser list: Limit height to left teaser box if screen is wide enough.
    if(isWithinBreakpoint('xs') || isWithinBreakpoint('sm'))
    {
        $('.news-teaser-list').css('height', '');
    }
    else
    {
        // console.log($('.box-teaser-and-news .height-ref').outerHeight(true));
        $('.news-teaser-list').css('height', '1px');
        $('.news-teaser-list').css('height', $('.box-teaser-and-news .height-ref').outerHeight(true) + 'px');
    }

}


function ds81_square_gallery_sizer() {
    $('.square-gallery-item').each(function () {
        $(this).find('.square-gallery-item-inner').css('height', '');
        $(this).find('.square-gallery-item-inner').css('height', $(this).width() + 'px');

        $(this).find('.square-gallery-item-inner').each(function () {

            if($(this).hasClass('focuspoint'))
            {
                $(this).data('focusPoint').adjustFocus();
            }

            if(!$(this).hasClass('focuspoint') && !$(this).hasClass('square-bg'))
            {
                $(this).addClass('square-bg');
                var $img = $(this).find('img').first();
                var imgSrc = $img.attr('src');
                var $picture = $img.closest('picture');
                if($picture.length > 0)
                {
                    if($('html').hasClass('webp'))
                    {
                        var $webp = $picture.find('[type="image/webp"]');
                        if($webp.length > 0)
                        {
                            imgSrc = $webp.attr('srcset');
                        }
                    }
                }
                $(this).css('background-image', 'url(' + imgSrc + ')');
                $img.css('visibility', 'hidden');
            }
        });


    });
}



function dsSetImageHeights()
{
    // Adjust images with height reference:
    $('[data-adjust-image-height-ref]').each(function () {
        var $root = $(this).parent();
        if(typeof $(this).data('adjust-image-height-root') !== "undefined")
        {
            $root = $(this).closest($(this).data('adjust-image-height-root'));
        }
        var $ref = $root.find('[data-height-for]').first();
        if($ref.length > 0)
        {
            $(this).css('height', $ref.innerHeight() + 'px');
        }

    });

    // Set image heights accoring to breakpoint (supersedes height reference);
    $('[data-i-height-xs],[data-i-height-sm],[data-i-height-md],[data-i-height-lg],[data-i-height-xl]').css('overflow-y', 'visible');
    var bb = getBreakPointName();
    switch(bb)
    {
        case('xs'):
            $('[data-i-height-xs]').each(function () {
                $(this).css('height', $(this).data('i-height-xs') + 'px');
            });
            break;
        case('sm'):
            $('[data-i-height-sm]').each(function () {
                $(this).css('height', $(this).data('i-height-sm') + 'px');
            });
            break;
        case('md'):
            $('[data-i-height-md]').each(function () {
                $(this).css('height', $(this).data('i-height-md') + 'px');
            });
            break;
        case('lg'):
            $('[data-i-height-lg]').each(function () {
                $(this).css('height', $(this).data('i-height-lg') + 'px');
            });
            break;
        default:
            $('[data-i-height-xl]').each(function () {
                $(this).css('height', $(this).data('i-height-xl') + 'px');
            });
            break;
    }
    // $('[data-i-height-xs],[data-i-height-sm],[data-i-height-md],[data-i-height-lg],[data-i-height-xl]').css('overflow-y', 'hidden');

    // Activate focuspoint on images:
    $('.focuspoint').focusPoint();

    // Trigger re-init on image load:
    $('img').each(function () {
        if(!$(this).hasClass('load-init') && !$(this).hasClass('loaded'))
        {
            $(this).addClass('load-init');
            $(this).one("load", function () {
                if(this.complete) {
                    $(this).addClass('loaded').removeClass('load-init');
                    dsSetImageHeights();
                }
            });
        }
    });

}



function ds81_init_videobox($target) {


    $target.on('click.ds_video_init', function (e) {

        $(this).off('click.ds_video_init');

        // Check cookie consent:
        if(dscookiebar_has_consent($(this).data('videotype')))
        {
            ds81_init_videobox_continue($(e.currentTarget));
        }
        else
        {
            $(this).parent().find('.cookie-agreement').removeClass('d-none').addClass('d-flex');
            $(this).parent().find('.cookie-agreement .btn-add-agreement').on('click.dscookieagreement', function (){
                var $myRoot = $(this).closest('.videocontainer').find('.videobox');
                dscookiebar_add_consent($myRoot.data('videotype'), function (_$myRoot){
                    _$myRoot.parent().find('.cookie-agreement').remove();
                    ds81_init_videobox_continue(_$myRoot);
                }, $myRoot);
            });
        }

    });


}

function ds81_is_iOS() {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

var plyrRunningInstance;

function ds81_init_videobox_continue($target) {


    $target.addClass('loading');
    var $root = $target.closest('.video-root').first();
    var tplHtml = $root.find('.video-embed').first().html();
    var tpl = _.template(tplHtml);
    var tplRendered = tpl({});

    $root.find('.videobox').append('<div class="videoembed"></div>');
    $root.find('.videobox .videoembed').html(tplRendered);
    var $plyrMain = $root.find('.videobox .videoembed .plyr_main').first();
    console.log('CONTINUE', $plyrMain.attr('id'));
    var p = new Plyr('#' + $plyrMain.attr('id'), {
        iconUrl: path_root + 'lib/plyr/plyr.svg',
        blankVideo: path_root + 'lib/plyr/blank.mp4',
        debug: false
    });

    // $plyrMain.css('opacity', 0);
    // $root.find('.loading-icon').removeClass('d-none');
    $root.find('.play-icon').addClass('d-none');
    p.on('error', function (e) {

        var $plyr = $(e.target);
        var $main = $plyr.closest('.videobox');
        $main.removeClass('loading');
        $main.find('.loading-icon').addClass('d-none');
        $main.find('.play-icon.d-none').removeClass('d-none');
        $main.find('.videoembed').remove();
        ds81_init_videobox($main);
        console.error('plyr error', e);


    });

    $plyrMain.css('opacity', 1);
    $root = $plyrMain.closest('.videocontainer');
    $root.find('.loading-icon').addClass('d-none');

    // if on ios: native player required. Show contents.
    if(ds81_is_iOS())
    {
        $plyrMain.css('opacity', 1);
        $root = $plyrMain.closest('.videocontainer');
        $root.find('.loading-icon').addClass('d-none');
    }

    p.on('ready', function (e) {
        var instance = e.detail.plyr;


        console.log("VIDEO READY FOR PLAYBACK", e, instance, instance.playing);

        var $plyr = $(e.target);
        var $main = $plyr.closest('.videobox');
        $main.removeClass('loading');


        instance.on('canplay', function (e) {
            console.log('canplay', e);
        });
        instance.on('statechange', function (e) {
            console.log('STATECHANGE', e);
        });
        instance.on('playing', function (e) {

            console.log("VIDEO PLAYING");

            $main.addClass('play-was-started');

            var instance = e.detail.plyr;

            if(plyrRunningInstance && !$(plyrRunningInstance.media).is($(instance.media)))
            {
                console.log('PAUSING OTHER INSTANCE', plyrRunningInstance);
                plyrRunningInstance.pause();
            }

            plyrRunningInstance = instance;
            console.log('UPDATING PLAYING INSTANCE', plyrRunningInstance);


            var $plyr = $(e.target);
            $plyr.css('opacity', 1);
            $root = $plyr.closest('.videocontainer');
            $root.find('.loading-icon').addClass('d-none');
            var $container = $root.closest('.image-holder');
            var $pointer = $container.find('.pointer');
            $pointer.animate({
                opacity: 0,
                right: -100
            }, 800, function (){
                $(this).remove();
            })
        });


        // If NOT on iOs: Safari bugfix: Needs an extra kick.
        if(!ds81_is_iOS())
        {
            setTimeout(function (){
                if(!instance.playing && !$main.hasClass('play-was-started'))
                {
                    $main.addClass('play-was-started');
                    instance.play();
                }
                console.log("VIDEO NOW PLAYING (1)");
            }, 1000);
            setTimeout(function (){
                if(!instance.playing && !$main.hasClass('play-was-started'))
                {
                    $main.addClass('play-was-started');
                    instance.play();
                }
                console.log("VIDEO NOW PLAYING (2)");
            }, 2000);
            setTimeout(function (){
                if(!instance.playing && !$main.hasClass('play-was-started'))
                {
                    $main.addClass('play-was-started');
                    instance.play();
                }
                console.log("VIDEO NOW PLAYING (3)");
            }, 3000);
            if(!instance.playing)
            {
                $main.addClass('play-was-started');
                console.log("VIDEO NOW PLAYING (0)");
                instance.play();
            }
        } else{

            console.log("VIDEO PLAYING?", instance.playing);
        }


    });
}


function dscookiebar_handle_consent_change()
{
    // Update onpage instances:
    var $targets = $('body').find('.dscookiebar,.consent-item-list-onpage');
    $targets.find('input[type="checkbox"]').each(function () {

        // Set current state:
        if (dscookiebar_has_consent($(this).attr('name'))) {
            $(this).prop('checked', true);
        }
        else
        {
            $(this).prop('checked', false);
        }

    });


    // Google Analytics:
    if(dscookiebar_has_consent('ga'))
    {
        dscookiebar_load_and_fire_tracking_calls();
    }

    // Show/hide consent elements:
    $('[data-consent-required]').each(function (){

        // Get consent id:
        var myConsent = $(this).data('consent-required');

        $('.addconsent').on('click', function (e){
            e.preventDefault();
            dscookiebar_add_consent($(this).data('addconsent'));
        });

        if(dscookiebar_has_consent(myConsent))
        {
            // Manage show/hide:
            $(this).find('[data-ifconsent="yes"]').removeClass('d-none');
            $(this).find('[data-ifconsent="no"]').addClass('d-none');

            // Parse template:
            var pluginTemplate = $(this).find('[data-ifconsent="yes"] .plugin-template').first();
            if(pluginTemplate.length > 0)
            {
                // console.log('IN');
                var tpl = _.template(pluginTemplate.html());
                var tplRendered = tpl({});
                $(this).find('[data-ifconsent="yes"]').html(tplRendered);
            }
        }
        else
        {
            // Manage show/hide:
            $(this).find('[data-ifconsent="yes"]').addClass('d-none');
            $(this).find('[data-ifconsent="no"]').removeClass('d-none');
        }

    });

}

function dscookiebar_add_consent(addConsent, callback){

    var $bar = $('body .dscookiebar').first();

    // Consent version id:
    var cookieVersion = $bar.data('version');

    // All IDs:
    var cookieIDs = $bar.data('cookies-list');

    // Consent found?
    var newConsentFound = false;

    var cookieStringList = [];
    for(var i in cookieIDs)
    {
        if(cookieIDs[i] == addConsent)
        {
            newConsentFound = true;
        }
        var setValue = 0;
        if(dscookiebar_has_consent(cookieIDs[i]) || cookieIDs[i] == addConsent)
        {
            setValue = 1;
        }
        cookieStringList.push(cookieIDs[i] + '=' + setValue);
    }
    var cookieString = cookieStringList.join('&');

    // Cookie path:
    var cookiePath = typeof path_root !== "undefined" ? path_root : '';

    // Set cookie using js-cookie helper (https://github.com/js-cookie/js-cookie)
    Cookies.set('dscookieconsent_' + lang_id, cookieString, { expires: 365, path: cookiePath });
    Cookies.set('dscookieconsent_version_' + lang_id, cookieVersion, { expires: 365, path: cookiePath });

    // Trigger event:
    $(window).trigger('dscookiebar_consentchange');

    // Callback?
    if(typeof callback == 'function')
    {
        // console.log('FN A', arguments);
        callback(arguments[2], arguments[3], arguments[4], arguments[5]);
    }

}


function dscookiebar_onpage_check_status() {

    // Check current status and enable save button if onpage settings do not match current consent:
    if($('#dscookiebar_onpage_consent').length > 0)
    {
        $('#dscookiebar_onpage_consent').each(function () {

            // Flag: All settings match current selection:
            var allMatch = true; // Assume true first.

            // Process checkboxes and check selection:
            $(this).find('input[type="checkbox"]').each(function () {

                // Check status:
                if(dscookiebar_has_consent($(this).attr('name')) != $(this).prop('checked'))
                {
                    // No match. Checkbox state does not equal current consent.
                    allMatch = false;
                }

            });

            // Set button status:
            $(this).find('.btn-save-consent-settings').prop('disabled', allMatch);
            if(allMatch)
            {
                $(this).find('.message-all-up-to-date').removeClass('d-none');
            }
            else
            {
                $(this).find('.message-all-up-to-date').addClass('d-none');
            }

        });
    }

}

function dscookiebar_has_consent(which) {
    var allConsents = dscookiebar_get_consents();
    return jQuery.inArray( which, allConsents) >= 0;
}

function dscookiebar_get_consents() {

    // Consent version id:
    var cookieVersion = $('#dscookiebar_consent').first().data('version');

    // Cookie path:
    var cookiePath = typeof path_root !== "undefined" ? path_root : '';

    // Prepeare return array:
    var ret = [];

    // Read cookie:
    if(typeof Cookies.get('dscookieconsent_version_' + lang_id, {path: cookiePath}) !== "undefined" && Cookies.get('dscookieconsent_version_' + lang_id, {path: cookiePath}) == cookieVersion)
    {
        // Get cookie using js-cookie helper (https://github.com/js-cookie/js-cookie)
        var consentData = Cookies.get('dscookieconsent_' + lang_id, { path: cookiePath });

        // Data from cookie obtained?
        if(typeof consentData !== "undefined")
        {
            var consentArray = deparam(consentData);
            for(var i in consentArray)
            {
                if(parseInt(consentArray[i]) > 0)
                {
                    ret.push(i);
                }
            }
        }

    }

    // Return given consents:
    return ret;

}

function dscookiebar_hide_bar($bar) {

    $('html').removeClass('dscookiebar-show-cover');

    $bar.find('.inner').animate({
        // height: 0,
        opacity: 0,
    }, {
        duration: 300,
        complete: function () {
            $(this).closest('.dscookiebar').hide();
        }
        // ,
        // step: function (now, tween) {
        // $(this).parent().css('height', $(this).outerHeight() + 'px');
        // }
    });

}

function dscookiebar_accept_all_handler(e) {

    if(typeof e !== "undefined")
    {
        e.preventDefault();
    }

    // Get references:
    var $button = $(e.currentTarget);
    var $bar = $button.closest('.dscookiebar');

    dscookiebar_hide_bar($bar);

    // Consent version id:
    var cookieVersion = $bar.data('version');

    // All IDs:
    var cookieIDs = $bar.data('cookies-list');
    var cookieStringList = [];
    for(var i in cookieIDs)
    {
        cookieStringList.push(cookieIDs[i] + '=1');
    }
    var cookieString = cookieStringList.join('&');

    // Cookie path:
    var cookiePath = typeof path_root !== "undefined" ? path_root : '';

    // Set cookie using js-cookie helper (https://github.com/js-cookie/js-cookie)
    Cookies.set('dscookieconsent_' + lang_id, cookieString, { expires: 365, path: cookiePath });
    Cookies.set('dscookieconsent_version_' + lang_id, cookieVersion, { expires: 365, path: cookiePath });

    // Trigger event:
    $(window).trigger('dscookiebar_consentchange');

}

function dscookiebar_show_select_consent_content(e) {

    e.preventDefault();

    var $button = $(e.currentTarget);
    var $bar = $button.closest('.dscookiebar');

    $bar.find('.content-select-consent').removeClass('d-none');
    $bar.find('.content-default').addClass('d-none');
    $('html').addClass('dscookiebar-show-cover');

}

function dscookiebar_show_default_content(e) {

    e.preventDefault();

    var $button = $(e.currentTarget);
    var $bar = $button.closest('.dscookiebar');

    $bar.find('.content-select-consent').addClass('d-none');
    $bar.find('.content-default').removeClass('d-none');
    $('html').removeClass('dscookiebar-show-cover');
}

function dscookiebar_save_consent(e) {

    e.preventDefault();

    var $button = $(e.currentTarget);
    var $form = $button.closest('form');

    // Find bar to obtain data:
    var $bar = $('#dscookiebar_consent').first();

    var checked = [];
    $form.find('input.consent-check:checked').each(function () {
        checked.push($(this).attr('name'));
    });

    dscookiebar_hide_bar($bar);

    // Consent version id:
    var cookieVersion = $bar.data('version');

    // All IDs:
    var cookieIDs = $bar.data('cookies-list');
    var mandatoryIDs = $bar.data('mandatory-list');
    var cookieStringList = [];
    for(var i in cookieIDs)
    {
        var myId = cookieIDs[i];
        var myState = 0;

        // Selected or mandatory?
        if(jQuery.inArray( myId, checked) >= 0 || jQuery.inArray( myId, mandatoryIDs) >= 0)
        {
            myState = 1;
        }

        cookieStringList.push(myId + '=' + myState);
    }
    var cookieString = cookieStringList.join('&');

    // Cookie path:
    var cookiePath = typeof path_root !== "undefined" ? path_root : '';

    // Set cookie using js-cookie helper (https://github.com/js-cookie/js-cookie)
    Cookies.set('dscookieconsent_' + lang_id, cookieString, { expires: 365, path: cookiePath });
    Cookies.set('dscookieconsent_version_' + lang_id, cookieVersion, { expires: 365, path: cookiePath });

    // Trigger event:
    $(window).trigger('dscookiebar_consentchange');
}

var dscookiebar_current_page_tracked = false;
var dsGtag;
function dscookiebar_load_and_fire_tracking_calls()
{

    // No tracking if backend user is logged in:
    if(typeof dp_prevent_count !== "undefined") return false;

    // dp_ga_uid set and has content?
    if(typeof dp_ga_uid === 'undefined' || dp_ga_uid.length < 1) return false;

    if(dscookiebar_current_page_tracked === false)
    {
        dscookiebar_current_page_tracked = true;
        dscookiebar_LoadJsCssFile('https://www.googletagmanager.com/gtag/js?id=' + dp_ga_uid, 'js', function () {
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', dp_ga_uid, { 'anonymize_ip': true });
            dsGtag = gtag;
        });
    }
}


// File loader:
function dscookiebar_LoadJsCssFile(filename, filetype, callback){
    if (filetype=="js"){ //if filename is a external JavaScript file
        var fileref=document.createElement('script')
        fileref.setAttribute("type","text/javascript")
        fileref.setAttribute("src", filename)
    }
    else if (filetype=="css"){ //if filename is an external CSS file
        var fileref=document.createElement("link")
        fileref.setAttribute("rel", "stylesheet")
        fileref.setAttribute("type", "text/css")
        fileref.setAttribute("href", filename)
    }

    if(typeof callback === "function")
    {
        fileref.onload = callback;
    }

    if (typeof fileref!="undefined")
        document.getElementsByTagName("head")[0].appendChild(fileref)
}


// File loader:
export var _us = _;
function loadJsCssFile(filename, filetype, callback){
    if (filetype=="js"){ //if filename is a external JavaScript file
        var fileref=document.createElement('script')
        fileref.setAttribute("type","text/javascript")
        fileref.setAttribute("src", filename)
    }
    else if (filetype=="css"){ //if filename is an external CSS file
        var fileref=document.createElement("link")
        fileref.setAttribute("rel", "stylesheet")
        fileref.setAttribute("type", "text/css")
        fileref.setAttribute("href", filename)
    }

    if(typeof callback === "function")
    {
        fileref.onload = callback;
    }

    if (typeof fileref!="undefined")
        document.getElementsByTagName("head")[0].appendChild(fileref)
}
